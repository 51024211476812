jQuery(document).ready(function($) {
	// Mobile menu
	$(".mobile-navigation-menu").mmenu({
		extensions: [
			"position-back",
			"position-top"
		],
		navbar: {
			add: true,
			title: ""
		},
		screenReader: {
			aria: true,
			text: true
		}
	});

	$(".mobile-menu-button").click(function() {
		if ($( ".mobile-navigation-menu" ).hasClass( "mm-opened" )) {
			$(".mobile-navigation-menu").data( "mmenu" ).close();
		}
		else {
			$(".mobile-navigation-menu").data( "mmenu" ).open();
		}
	});

	/* Sticky Header */
	window.onscroll = function() {
		myFunction()
	};
	var header = document.getElementById("main-header");
	var sticky = header.offsetTop;

	function myFunction() {
		if (window.pageYOffset > sticky) {
			header.classList.add("sticky");
		}
		else {
			header.classList.remove("sticky");
		}
	}

	// Apply FitVids to YouTube videos
	if ($("body").innerWidth() <= 767 ) {
		$(".main-content-container").fitVids({ ignore: ".nofit" });
	}
});
